import * as React from 'react'
import { siteMetadata } from '../../gatsby-config'

const Footer = (props) => {
  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">Get in touch</h2>
        <p>Want us to read a specific series? Or maybe you have a cool collaboration idea in mind? Or maybe you just wanted to tell us we're cool? Send us a message, email us, or check us out on any of the social media platforms listed below. We'd love to hear from you!</p>
        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="contact" />
          <div className="fields">
            <div className="field">
              <label for="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field">
              <label for="email">Email</label>
              <input type="email" name="email" id="email" />
            </div>
            <div className="field">
              <label for="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
          </div>
          <ul className="actions">
            <li><input type="submit" value="Send Message" /></li>
          </ul>
        </form>
        <ul className="contact">
          <li className="icon solid fa-envelope"><a href="mailto:overmangacast@gmail.com">overmangacast@gmail.com</a></li>
          <li className="icon brands fa-twitter"><a href="https://twitter.com/OverMangaCast">twitter.com/OverMangaCast</a></li>
          <li className="icon brands fa-facebook-f"><a href="https://facebook.com/OverMangaCast">facebook.com/OverMangaCast</a></li>
          <li className="icon brands fa-instagram"><a href="https://instagram.com/OverMangaCast">instagram.com/OverMangaCast</a></li>
          <li className="icon brands fa-youtube"><a href="https://www.youtube.com/channel/UCkYO_MheihNzpnC1Ya1iiog">youtube.com/OverMangaCast</a></li>
        </ul>
        <ul className="copyright">
          <li>&copy;{siteMetadata.year} All rights reserved.</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
        </ul>
      </div>
    </section>
  )
}

export default Footer