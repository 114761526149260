module.exports = {
  siteMetadata: {
    title: `OverMangaCast`,
    siteUrl: `https://www.yourdomain.tld`,
    year: new Date().getFullYear()
  },
  plugins: [
    'gatsby-plugin-sass',
    'gatsby-transformer-yaml',
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `episodes`,
        path: `${__dirname}/src/episodes`,
      }
    },
    {
      resolve: `gatsby-source-rss-feed`,
      options: {
        url: `https://feeds.redcircle.com/ec444116-0496-49d7-a330-edcc1f2026d0`,
        name: `OverMangaCast`,
      }
    }
  ],
}
