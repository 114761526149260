import * as React from "react"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
      <div className="inner">
        <h2>Menu</h2>
        <ul className="links">
          <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
          <li><Link onClick={props.onToggleMenu} to="/episodes/">Episodes</Link></li>
          <li><Link onClick={props.onToggleMenu} to="/#two">Support</Link></li>
          <li><Link onClick={props.onToggleMenu} to="/#three">About Us</Link></li>
          <li><Link onClick={props.onToggleMenu} to="/#footer">Contact</Link></li>
        </ul>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
      </div>
    </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func
}

export default Menu