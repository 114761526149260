import * as React from "react"
import '../assets/sass/main.scss'
import '../assets/sass/noscript.scss'
import Menu from "./menu"
import Header from "./header"
import Footer from "./footer"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuVisible: false,
      loading: 'is-loading'
    }
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
  }

  componentWillUnmount () {
      if (this.timeoutId) {
          clearTimeout(this.timeoutId);
      }
  }

  handleToggleMenu() {
      this.setState({
          isMenuVisible: !this.state.isMenuVisible
      })
  }
  render(){
    const {children} = this.props

    return (
      <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
          <div>
              <Header onToggleMenu={this.handleToggleMenu} />
              {children}
              <Footer />
          </div>
          <Menu onToggleMenu={this.handleToggleMenu} />
      </div>
    )
  }
}

export default Layout