import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <body class="is-preload">
        <div id="page-wrapper">
          <section id="wrapper">
            <header>
              <div class="inner">
                <h2>404 - Page Not Found</h2>
                <p>There doesn't appear to be anything here...</p>
              </div>
            </header>
          </section>
          <section class="wrapper spotlight alt style1">
            <div class="inner">
              <div class="content">
                <p>Looks like you got lost on the path of life, or maybe mistyped the URL you were looking for? Do people still do that? Type URLs? Anyway, you'll probably want to navigate back to the main site via the menu button, or just check out our episodes here:</p>
                <Link to="/episodes/" className="button">All Episodes</Link>
              </div>
            </div>
          </section>
        </div>
      </body>
    </Layout>
  )
}

export default NotFoundPage
